import React from "react";
import { Max } from "../utils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Typography, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default () => {
  const classes = useStyles();
  const theme = useTheme();
  return (

      <Max
        row={{
          style: {
            height: "100vh",
          },
        }}
      >
        <Typography variant="body1" gutterBottom>
          Development / Animation
        </Typography>
        <Typography
          variant={useMediaQuery(theme.breakpoints.up("sm")) ? "h3" : "h4"}
          gutterBottom
        >
          Joseph Emswiler
        </Typography>
        <a href="mailto:josephemswiler@gmail.com?subject=Hi 👋&body=">
          <Button className={classes.button}>
            <span role="img" aria-label="wave emoji">
              👋
            </span>{" "}
            Say Hi
          </Button>
        </a>
      </Max>

  );
};
