import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
// import { TopBar, Footer } from "./components";
import { Home } from "../containers";

export default () => (
  <ThemeProvider
    theme={createMuiTheme({
      typography: {
        fontFamily: "Muli",
        h1: {
          fontWeight: 800,
        },
        h2: {
          fontWeight: 800,
        },
        h3: {
          fontSize: "3em",
          fontWeight: 800,
        },
        h4: {
          fontSize: "2em",
          fontWeight: 800,
        },
      },
      breakpoints: {
        values: {
          lg: 1000,
          md: 960,
          sm: 450,
          xl: 1400,
          xs: 360,
          xxs: 0,
        },
      },
      // app: {
      //   max: 1000,
      //   gutter: {
      //     sides: {
      //       sm: 3,
      //       lg: 6,
      //     },
      //     top: {
      //       sm: 6,
      //       lg: 9,
      //     },
      //     bottom: {
      //       sm: 3,
      //       lg: 6,
      //     },
      //   },
      // },
      palette: {
        background: {
          // paper: "#F4D6BE",
          // default: "#F4D6BE",
        },
        brand: {
          red: "#FBA680",
          redShade: "#F17A5A",
          blue: "#83B0B5",
          navyShade: "#081F2D",
          navy: "#0B1644",
          navyTint: "#1E313F",
          offWhite: "#F4D6BE",
          green: "#5DB59D",
          greenTint: "#8BCCB8",
          gold: "#FAD390",
        },
        primary: {
          main: "#0B1644",
        },
        secondary: {
          main: "#FAD390",
        },
        // text: {
        //   primary: "#0B1644",
        // },
      },
      overrides: {
        MuiTypography: {
          root: {
            maxWidth: 1000,
          },
        },
        MuiButton: {
          root: {
            minWidth: "initial",
          },
        },
      },
    })}
  >
    <CssBaseline />
    <Router>
      {/* <TopBar /> */}
      <Route path="/" exact component={Home} />
      {/* <Route path="/about/" component={About} />
      <Route path="/services/" component={Services} /> */}
      {/* <Footer /> */}
    </Router>
  </ThemeProvider>
);
