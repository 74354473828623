import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  row: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // paddingTop: theme.spacing(10),
    paddingRight: theme.spacing(5),
    // paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    maxWidth: 1000,
  },
}));

export default props => {
  const classes = useStyles();

  return (
    <div
      {...{
        ...props.row,
        style: props.row && props.row.style,
        className: classes.row,
      }}
    >
      <div
        {...{
          ...props.column,
          style: props.column && props.column.style,
          className: classes.column,
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
