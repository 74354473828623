const initialState = {};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SHOW_EMAIL_DIALOG": {
      return payload;
    }
    default: {
      return state;
    }
  }
};
